<template>
    <div class="info-box" :style="baseStyle" @click="toggleInfo">

        <div class="info" :data-show="showInfo" :style="infoStyle" v-if="showInfo">
            <slot>
                Info text here.
            </slot>
        </div>

        <div class="indicator" :class="{ hoverable: hover }"><svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
          <path fill="#FFF" d="M29.25,14.625H19.125V4.5a2.25,2.25,0,0,0-2.25-2.25h-2.25a2.25,2.25,0,0,0-2.25,2.25V14.625H2.25A2.25,2.25,0,0,0,0,16.875v2.25a2.25,2.25,0,0,0,2.25,2.25H12.375V31.5a2.25,2.25,0,0,0,2.25,2.25h2.25a2.25,2.25,0,0,0,2.25-2.25V21.375H29.25a2.25,2.25,0,0,0,2.25-2.25v-2.25A2.25,2.25,0,0,0,29.25,14.625Z" transform="translate(0 -2.25)"/>
        </svg>
          <span v-if="hover"></span></div>

    </div>
</template>

<script>
    export default {
        name: "InfoBox",
        props: {
            active: {
                default: true
            },

            hover: {
              default: true
            },

            align: {
                default: "right top"
            },

            left: {
                default: ""
            },

            top: {
                default: ""
            },

            bottom: {
                default: ""
            },

            right: {
                default: ""
            },

            width: {
                default: "auto"
            },

            height: {
                default: "auto"
            },
        },

        data: function(){
            return {
                showInfo: false
            }
        },

        methods: {
            toggleInfo: function(){
                this.showInfo = !this.showInfo;
            }
        },
        computed: {
            baseStyle: function(){

                let style = {};



                if(this.top){
                    style.top = this.top;
                }

                if(this.bottom){
                    style.bottom = this.bottom;
                }

                if(this.right){
                    style.right= this.right;
                }

                if(this.left){
                    style.left = this.left;
                }

                return style;
            },

            infoStyle: function(){
                let style = {};

                let alignment = this.align.split(" ");

                let horizontal = alignment[0];
                let vertical = alignment[1];

                if( horizontal == "left"){
                    style.left = "0%";
                }

                if( horizontal == "right"){
                    style.right = "0%";
                }

                if( horizontal == "middle"){
                    style.left = "50%";
                    style.transform = "translateX(-50%)";
                }

                if( vertical == "top"){
                    style.top = "0%";
                }

                if( vertical == "bottom"){
                    style.bottom = "0%";
                }

                if( vertical == "middle"){
                    style.top = "50%";
                    style.transform = "translateY(-50%)";
                }

              if(this.width){
                style.width = this.width;
              }

                return style;
            }
        }
    }
</script>

<style scoped>

    @keyframes blink {
        0% {
            transform: scale(1.0);
        }

        50% {
            transform: scale(0.75);
        }

        100% {
            transform: scale(1.0);
        }
    }




    .info-box {
        font-size: 1.0em;
        position: absolute;

    }

    .info-box p {
        margin: 0;
        font-size: 1.1em;
        line-height: 1.5em;
    }

    .info > p + p {
        margin-top: 1em;
    }

    .indicator {
        width: 1em;
        height: 1em;
        border-radius: 2em;
        background-color: var(--color-green);
        position: absolute;
        top: -0.75em;
        left: -0.75em;
        display: flex;
        justify-content: center;
        align-items: center;

        color: white;
        border: 4px solid var(--color-green);
        box-sizing: border-box;
        box-shadow: 0 0 0px 5px rgba(87,194,178,0.3);
        user-select: none;
        cursor: pointer;




    }

    .indicator svg {
      position: absolute;
      width: 1em;
      height: 1em;

    }

    .indicator.hoverable {
      width: 2em;
      height: 2em;
      top: -0.75em;
      right: -0.75em;


    }



    .info {
        position: absolute;
        transition: opacity 0.3s;
        padding: 3em;
        background-color: #f0f0f0;
        border: 1px solid #dedede;
        color: #080808;
        box-sizing: border-box;
      display: block;
      opacity: 1.0;


    }

    .info-box:hover .info {

    }

    .info-box:hover .indicator {

    }

</style>
