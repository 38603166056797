<template>
  <div class="comment-box" :class="(comment.status==='resolved')?'deleted':''">
    <div class="meta">
      <div class="avatar" :style="avatarStyle"><span>{{ comment.initials }}</span></div>
      <div>
        <div class="user">{{ comment.userName }}</div>
        <div class="date">{{ dateString }} <span v-if="comment.status === 'resolved'" class="resolved-label">RESOLVED</span></div>
      </div>
      <div>
        <div class="icon-button edit-button" @click="onEditClick">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33.182 33.182">
            <g>
              <path  d="M16.5,6H6A3,3,0,0,0,3,9V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V19.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path   d="M27.75,3.75a3.182,3.182,0,0,1,4.5,4.5L18,22.5,12,24l1.5-6Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="resolved-icon">

    </div>
    <div class="body">
      <div class="comment-panel" v-if="editComment">
        <textarea class="comment-input" rows="5" placeholder="Comment here" @focus="$emit('focus')" @blur="$emit('blur')" v-model="commentInput"></textarea>
        <div class="comment-buttons">
          <div class="button-group">
            <button v-if="confirmation && !feedbackAdmin" class="button button-small" :class="(confirmation==='Delete')?'button-destructive':'button-success'" @click="onConfirmDelete">Confirm {{ confirmation }}?</button>
            <div class="" v-else>

                <select v-if="feedbackAdmin" class="options-selector" title="Status" v-model="commentStatus" @change="onStatusChange">
                  <option value="active">Active</option>
                  <option value="resolved">Resolved</option>
                  <option value="deleted">Delete</option>
                </select>
              <div v-else class="icon-button delete-button" @click="onDeleteClick" title="Delete">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="36" viewBox="0 0 31.5 36">
                  <path id="Icon_awesome-trash-alt" data-name="Icon awesome-trash-alt" d="M2.25,32.625A3.375,3.375,0,0,0,5.625,36h20.25a3.375,3.375,0,0,0,3.375-3.375V9h-27Zm19.125-18a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0Zm-6.75,0a1.125,1.125,0,0,1,2.25,0v15.75a1.125,1.125,0,0,1-2.25,0ZM30.375,2.25H21.938L21.277.935A1.688,1.688,0,0,0,19.765,0H11.728a1.668,1.668,0,0,0-1.5.935L9.563,2.25H1.125A1.125,1.125,0,0,0,0,3.375v2.25A1.125,1.125,0,0,0,1.125,6.75h29.25A1.125,1.125,0,0,0,31.5,5.625V3.375A1.125,1.125,0,0,0,30.375,2.25Z" transform="translate(0 0)"/>
                </svg>
              </div>

            </div>
          </div>
          <div class="button-group">
            <button class="button button-small button-basic" @click="onCancelEdit">Cancel</button>
            <button class="button button-small" @click="onSubmitComment" :class="(confirmation === 'deleted')?'destructive':''" :disabled="!hasChanged || saving">{{ saving?'Saving...':'Submit'}}</button>
          </div>

        </div>

      </div>
      <p v-else>{{ comment.body }}</p>

    </div>

  </div>
</template>

<script>
export default {
  name: "CommentBox",
  props: {
    comment: {
      type: Object,
      required: true
    },
    avatarColor: {
      type: String,
      default: '#AAA'
    },
    editorLevel: {
      type: Number,
      default: 0
    }
  },
  data: function(){
    return {
      editComment: false,
      commentInput: "",
      commentStatus: "",
      saving: false,
      confirmation: false,
      options: false,
    }
  },
  methods: {

    onEditClick: function(){
      if(!this.editComment){
        this.commentInput = this.comment.body;
        this.commentStatus = this.comment.status;
        this.editComment = true;
      }else{
        this.commentInput = "";
        this.editComment = false;
        this.confirmation = false;
        this.commentStatus = ""
      }

    },

    onDeleteClick: function(){
      this.options = false;
      this.confirmation = "Delete";
    },

    onStatusChange: function(){
      this.confirmation = this.commentStatus;
    },

    onResolveClick: function(){
      this.options = false;
      this.confirmation = "Resolve";
    },

    onOptionsClick: function(){
      this.options = !this.options;
      this.confirmation = false;
    },

    onConfirmDelete: function(){
      let updatedComment = {...this.comment};
      updatedComment.status = "deleted";
      updatedComment.date = Date.now();
      this.$emit('delete',updatedComment)
      this.editComment = false;
    },

    onCancelEdit: function(){
      this.commentInput = "";
      this.editComment = false;
      this.confirmation = false;
    },



    onSubmitComment: function(){

      let updatedComment = {...this.comment};
      updatedComment.body = this.commentInput;
      updatedComment.status = this.commentStatus;
      updatedComment.date = Date.now();
      this.$emit('save',updatedComment)
      this.editComment = false;
      this.confirmation = false;
    }
  },

  computed: {
    feedbackAdmin: function(){
      return (this.editorLevel);
    },

    hasChanged: function(){
      return ((this.comment.body !== this.commentInput) || (this.comment.status !== this.commentStatus));
    },

    avatarStyle: function(){
      return {
        backgroundColor: this.avatarColor
      }
    },

    dateString: function(){
      return this.$timeAgo.format(this.comment.date);

      //return (new Date(this.comment.date)).toLocaleDateString();
    },
  }
}
</script>

<style scoped>
  .comment-box {
    border: 1px solid #ccc;
    padding: 0.5em;
    border-radius: 0.5em;
    margin: 0.5em 0;
    background-color: white;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }

  .comment-box .meta {
    display: flex;
    width: 100%;
    align-items: center;



  }

  .comment-box .avatar {
    font-size: 0.75em;
    font-weight: bold;
    color: white;
    background-color: #ccc;
    text-align: center;
    padding: 0.5em;
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 100%;
    margin-right: 0.5em;
  }


  .comment-box .user {
    font-size: 0.8em;
    font-weight: bold;
  }

  .comment-box .date {
    font-size: 0.7em;
    color: #888;
  }

  .comment-box .body {
    font-size: 0.8em;
    color: #333;
    margin-top: 0.5em;
    position: relative;

  }

  .comment-box .body p {
    margin: 0;
    white-space: pre-wrap;
  }

  .icon-button {
    width: 1em;
    height: 1em;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .icon-button label {
    position: absolute;
    right: 100%;
    font-size: 0.8em;
    margin-right: 1em;
    display: none;

    color: black;
    width: 100px;
    text-align: right;
    color: #888;
  }

  .icon-button svg {
    width: 0.75em;
    height: 0.75em;
  }
  
  .delete-button {
    position: relative;
    opacity: 1.0;
  }
  


  .delete-button svg {
    width: 2em;
    height: 2em;
  }

  .delete-button:hover svg {
    opacity: 1.0;
  }


  .icon-button:hover {
      opacity: 1.0;
  }



  .icon-button:hover label {
    display: block;
  }

  .edit-button {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }

  .comment-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5em 0;
  }

  .button-group {
    display: flex;
    align-items: center;
    position: relative;
  }

  .comment-input {
    resize: none;
    width: 100%;
    border-radius: 5px;
    padding: 0.6em 0.8em;
    box-sizing: border-box;
    border-color: #aaa;

  }

  .button + .button {
    margin-left: 0.5em;
  }

  .button-small {
    font-size: 12px;
  }

  .button-basic {
    border: none;
    background-color: transparent;
    color: #888;
  }

  .button-basic:hover {
    border: none;
    background-color: #eee;
    color: #555;
  }

  .button-destructive {
    background-color: red;
  }

  .button[disabled]{
    background-color: #eee;
    color: #555;
  }

  .delete-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    border-radius: 3px;
    left: 0;
    padding: 0.5em;
    background-color: #fff;
    border: 1px solid #aaa;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);


  }

  .options-button {
    position: relative;
    display: inline-block;
    width: 1.5em; height: 1.5em;
    margin-right: 0.5em;
  }

  .options-button svg {
    width: 1.5em;
    height: 1.5em;
  }

  .option-link {
    font-size: 12px;
    margin: 4px;
    display: flex;
    align-items: center;
    opacity: 0.8;
    cursor:pointer;
  }

  .option-link svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    fill: currentColor;
  }

  .option-link:hover {
    opacity: 1.0;
    color: red;
  }

  .option-link:hover span {
    text-decoration: underline;
  }

  .option-link.resolve:hover {
    color: var(--color-green);
  }

  .delete {
    color: #ccc;
    cursor: pointer;
    opacity: 1.0;
  }

  .delete:hover {
    color: red;
  }

  .delete svg {
    fill: currentColor;
  }

  .resolve {
    color: #ccc;
    cursor: pointer;
    opacity: 1.0;
  }

  .resolve:hover {
    color: var(--color-green);
  }

  .resolve svg {
    fill: currentColor;
  }

  .button-success {
    background-color: var(--color-green);
  }

  .options-selector {
    padding: 0.3em 0.5em;
    border: 1px solid #aaa;
    border-radius: 5px;
  }

  .button.destructive {
    background-color:red;
  }

  .comment-box:hover {
    border-color: black;
  }

  .comment-box.deleted {
    border-color: #eee;
  }

  .comment-box.deleted:hover {
    opacity: 1.0;
  }

  .resolved-label {
    color: white;
    background: var(--color-primary);
    padding:  0 5px;
  }







</style>