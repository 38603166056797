<template>
  <div class="container">
    <header class="feedback-header">
      <div class="user-label"><span>{{ editorLevel?"Admin":"User"}}</span>: {{ userName }}</div>

      <div class="icon-button close-feedback-button" @click="close">

        <label>Hide Feedback</label>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
          <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
        </svg>
      </div>

    </header>
    <div class="comment-panel">
      <textarea class="comment-input" :rows="editMode?5:1" placeholder="Comment here" @click="enterComment" @focus="onTextFocus" @blur="onTextBlur" v-model="commentInput"></textarea>
      <div class="comment-buttons">
        <button class="button button-small button-basic" :disabled="!editMode" @click="onCancelEdit">Cancel</button>
        <button class="button button-small" :disabled="!editMode || saving" @click="onCreateComment">{{ saving?'Saving...':'Submit'}}</button>
      </div>
    </div>
    <div class="total-count-label">TOTAL ACTIVE: {{ commentCount.active }} <span class="show-all-toggle" @click="toggleAll">({{ commentFilters.screenOnly?'show all':'hide all but current'}})</span></div>
    <div class="feedback-items">
      <div class="loading" v-if="!showAllFeedback && !groupedComments[screenPath]">No comments for this screen. <p>You are in "current screen only" mode. Would you like to show <a href="#" @click="showAllFeedback">comments for all screens</a> instead?</p></div>
      <div class="feedback-group" v-for="group in groupedCommentsList" :key="group[0].screen">
        <div class="page-title">
          <p><router-link :to="group[0].screen">{{ group[0].screen }}</router-link></p>
          <label>{{ group.length }} Comment{{ (group.length > 1)?'s':''}} <span class="group-date">{{ $timeAgo.format(group[0].date) }}</span></label>

        </div>
        <div class="feedback-list">

            <comment-box v-for="comment in group" :key="comment.date" :comment="comment" :avatar-color="colorTable[comment.userName]" :editor-level="editorLevel" @save="onEditComment" @delete="onDeleteComment" @focus="onTextFocus" @blur="onTextBlur"></comment-box>
        </div>
      </div>
    </div>

   <div class="feedback-footer">
     <div class="filters">
       <span class="icon" @click="showFilters = !showFilters">
         <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
  <g id="Icon_ionic-md-options" data-name="Icon ionic-md-options" transform="translate(-2.25 -2.25)">
    <g id="Group_105" data-name="Group 105">
      <path id="Path_500" data-name="Path 500" d="M2.25,27H21.375v2.25H2.25Z"/>
      <path id="Path_501" data-name="Path 501" d="M28.125,27H33.75v2.25H28.125Z"/>
      <path id="Path_502" data-name="Path 502" d="M27,31.465a2.268,2.268,0,0,1-2.25,2.285h0a2.268,2.268,0,0,1-2.25-2.285v-6.68A2.268,2.268,0,0,1,24.75,22.5h0A2.268,2.268,0,0,1,27,24.785Z"/>
    </g>
    <g id="Group_106" data-name="Group 106">
      <path id="Path_503" data-name="Path 503" d="M2.25,16.875H7.875v2.25H2.25Z"/>
      <path id="Path_504" data-name="Path 504" d="M14.625,16.875H33.75v2.25H14.625Z"/>
      <path id="Path_505" data-name="Path 505" d="M13.5,21.34a2.268,2.268,0,0,1-2.25,2.285h0A2.268,2.268,0,0,1,9,21.34V14.66a2.268,2.268,0,0,1,2.25-2.285h0A2.268,2.268,0,0,1,13.5,14.66Z"/>
    </g>
    <g id="Group_107" data-name="Group 107">
      <path id="Path_506" data-name="Path 506" d="M2.25,6.75H21.375V9H2.25Z"/>
      <path id="Path_507" data-name="Path 507" d="M28.125,6.75H33.75V9H28.125Z"/>
      <path id="Path_508" data-name="Path 508" d="M27,11.215A2.268,2.268,0,0,1,24.75,13.5h0a2.268,2.268,0,0,1-2.25-2.285V4.535A2.268,2.268,0,0,1,24.75,2.25h0A2.268,2.268,0,0,1,27,4.535Z"/>
    </g>
  </g>
</svg>

        </span>
       <button class="filter-item" :class="filterStateLabel.warning" @click="showFilters = !showFilters">{{ filterStateLabel.text }}</button>
     </div>
     <div class="filter-editor" v-if="showFilters">
       <div class="filter-input">
         <input type="checkbox" @change="onFilterChange" v-model="commentFilters.active"/>
         <label>Show Active</label>
       </div>

       <div class="filter-input">
         <input type="checkbox" @change="onFilterChange" v-model="commentFilters.resolved"/>
         <label>Show Resolved</label>
       </div>
       <div class="filter-input">
         <input type="checkbox" @change="onFilterChange" v-model="commentFilters.screenOnly"/>
         <label>Show Only Current Screen</label>
       </div>
     </div>
   </div>

  </div>
</template>

<script>

import CommentBox from "@/components/CommentBox";

export default {
  name: "feedback-editor",
  components: {CommentBox},
  data: function(){
    return {
      feedbackPanel: false,
      editMode: false,
      commentInput: "",
      comments: [],
      saving: false,
      loading: false,
      apiDomain: "https://pcx-flex-demo-2021.vercel.app/api",

      allLoaded: false,
      colorTable: {},
      editorLevel: 0,
      showFilters: false,
      commentFilters: {
        active: true,
        resolved: true,
        screenOnly: false
      }
    }
  },

  mounted() {

    this.loadData();

  },

  computed: {

    showAllFeedback: function(){
      return !this.commentFilters.screenOnly;
    },

    filterStateLabel: function(){

      let label = {
        text: this.commentFilters.screenOnly?"Current Screen Only ":"Showing All Screens ",
        warning:  this.commentFilters.screenOnly?"attention":""
      }

      if( this.commentFilters.active && this.commentFilters.resolved){
        label.text += "(Active & Resolved)"
      }else{
        if(this.commentFilters.active || this.commentFilters.resolved){
          label.text += this.commentFilters.active?"(Active Only)":"(Resolved Only)";
        }else{
          label.text = "Warning: You are hiding all screen states!"
          label.warning = "warning";

        }

      }

      return label;
    },

    userName: function(){

      if(!this.$store.state.sso && this.$store.state.flexUsername){
        return this.$store.state.flexUsername.split("@")[0];
      }
      return this.$store.state.account?this.$store.state.account.name:"Test User";
    },

    userEmail: function(){
      if(this.$store.state.account && this.$store.state.account.email){
        return this.$store.state.account.email;
      }else{
        if(!this.$store.state.sso && this.$store.state.flexUsername){
          return this.$store.state.flexUsername;
        }
      }
      return ""
    },

    screenPath: function(){
      return this.$route.fullPath
    },

    commentCount: function(){
      let count = {
        active: 0,
        resolved: 0
      }

      this.comments.forEach((comment) => {
        if(comment.status === 'active'){
          count.active++;
        }else if(comment.status === 'resolved'){
          count.resolved++;
        }
      })

      return count;
    },

    commentsViewable: function(){
      return this.comments.filter((comment) => {
        if(comment.status){
          if(this.commentFilters.resolved || this.commentFilters.active){
            if((comment.status === 'active' && this.commentFilters.active) || (comment.status === 'resolved' && this.commentFilters.resolved)){
                return true
            }
          }
        }
      })
    },



    groupedCommentsList: function(){

      let groupList = Object.keys(this.groupedComments).map((key) =>{
        return this.groupedComments[key]
      }).sort((a,b) => {
        return b[0].date - a[0].date;
      })

      if(this.showAllFeedback){
        return groupList;
      }else{
        return groupList.filter((group) => {
          return (group[0].screen === this.screenPath);
        })
      }
    },

    groupedComments: function(){

      return this.commentsViewable.reduce((screens, comment) => {
          if(!screens[comment.screen]){
            screens[comment.screen] = []
          }

          if(!this.colorTable[comment.userName]){
            this.colorTable[comment.userName] = this.stringToHslColor(comment.userName, 75, 75);
          }

          screens[comment.screen].push(comment);



          return screens;
      }, {})
    },
  },
  methods: {

    toggleAll: function(){
      this.commentFilters.screenOnly = !this.commentFilters.screenOnly;
    },

    onFilterChange: function(){},

    getComments: async function(username, status){

      const url = `${this.apiDomain}/feedback`
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {
              auth: this.$store.state.account,
              action: "get",
              params: {
                status,
                username
              }
            }
        )
      }

      let records = fetch(url,options).then((response) => {
        return response.json()
      }).catch((error) => {
        console.log(error);
      })

      return records;

    },

    stringToHslColor: function(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      let h = hash % 360;
      return 'hsl('+h+', '+s+'%, '+l+'%)';
    },

    loadData: function(){
      //let screenId = this.showAllFeedback?"":this.$route.fullPath;
      let status = ['active','resolved'];

      this.comments = [];

      this.loading = true;
      this.getComments(this.userName, status).then((response) => {
        this.loading = false;
        //console.log(response);
        this.allLoaded = true;

        this.editorLevel = response.level

        if(response.data){
          this.comments = response.data.map((comment) => {
            return this.assembleComment(comment)
          })
        }else{
          throw new Error("Network error.");
        }
      }).catch((error) => {
        this.loading = false;
        console.log("Load Error", error)
      })
    },

    assembleComment: function(data){
      let comment = {
        id: data[1],
        date: data[0]/1000,
        initials: this.getInitials(data[2]),
        userName: data[2],
        screen: data[3],
        body: data[4],
        status: data[5]
      }

      return comment
    },




    close: function(){
      this.$store.commit("showFeedback", false)
    },

    onCancelEdit: function(){
      this.commentInput = "";
      this.editMode = false;
    },

    onDeleteComment: async function(comment){

      this.saving = true;
      //console.log("delete", comment)

      const url = `${this.apiDomain}/comment`
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {
              auth: this.$store.state.account,
              action: "delete",
              params: {
                record: comment
              }
            }
        )
      }

      await fetch(url,options).then((response) => {
        return response.json()
      }).then((receipt) => {
        if(receipt.error){
          throw new Error(receipt.error)
        }
        console.log("delete response", receipt);

        let updatedIndex = this.comments.findIndex((item) => {
          return (item.id === comment.id);
        })

        //console.log("index updated", updatedIndex)

        if(updatedIndex !== -1){
          this.comments.splice(updatedIndex,1)
        }
      }).catch((error) => {
        console.log("Error",error);
      })

      this.saving = false;
      this.commentInput = "";
      this.editMode = false;
    },

    onEditComment: async function(comment){

      this.saving = true;
      //console.log("update", comment)

      const url = `${this.apiDomain}//comment`
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {
              auth: this.$store.state.account,
              action: "update",
              params: {
                record: comment
              }
            }
        )
      }

      await fetch(url,options).then((response) => {
        return response.json()
      }).then((response) => {
        if(response.error){
          throw new Error('Operation failed')
        }
        let updatedIndex = this.comments.findIndex((item) => {
          return (item.id === comment.id);
        })

        console.log("index updated", updatedIndex)

        if(updatedIndex !== -1){
          this.$set(this.comments, updatedIndex, comment);
        }
      }).catch((error) => {
        console.log("Save Error", error)
      })

      this.saving = false;
      this.commentInput = "";
      this.editMode = false;
    },

    onCreateComment: async function(){

      //if(this.saving){ return }

      let comment = this.generateComment(this.commentInput);
      this.saving = true;

      const url = `${this.apiDomain}//comment`
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(
            {
              auth: this.$store.state.account,
              action: "create",
              params: {
                record: comment
              }
            }
        )
      }

      let sendReceipt = false;

      await fetch(url,options).then((response) => {
        return response.json()
      }).then((receipt) => {

        if(receipt.error){
          throw new Error("Operation failed");
        }
        console.log("create response", receipt)
        comment.id = receipt.ref['@ref'].id;
        this.comments.unshift(comment)
        sendReceipt = true;
      }).catch((error) => {
        alert("This operation could not be completed. Please try again later.")
        console.log("Save Error", error)
      })

      if(sendReceipt){
        await fetch(`${this.apiDomain}//notification`,{
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: this.userName,
            email: this.userEmail?this.userEmail:"",
            comment: comment.body,
            screen: comment.screen,
          })

        }).then((response) => {
          return response.json()
        }).then((receipt) => {

          if(receipt.error){
            throw new Error("Notification failed");
          }
          return receipt
        }).catch((error) => {
          alert("This operation could not be completed. Please try again later.")
          console.log("Notify Error", error)
        })

        //console.log(notification)
      }else{
        console.log("Notification failed...", this.userEmail)
      }





      this.saving = false;
      this.commentInput = "";
      this.editMode = false;


    },

    enterComment: function(){
      this.editMode = true;
    },

    onTextFocus: function(){
      this.$store.state.feedbackInput = true;

    },

    onTextBlur: function(){
      this.$store.state.feedbackInput = false;

    },

    getInitials: function(name){

      if(name){
        return name.split(" ").reduce((letters, name)=>{
          if(letters.length < 2){
            letters = letters + name.substr(0,1);
          }

          return letters
        }, "")
      }else{
        return "?"
      }

    },

    generateComment: function(comment){



      return {
        date: Date.now(),
        initials: this.getInitials(this.userName),
        userName: this.userName,
        screen: this.screenPath,
        body: comment,
        status: "active"
      }
    }
  }
}
</script>

<style scoped>

  .container {
    background-color: #fbfcfd;
    font-size: 16px;
    display: flex;
    flex-direction: column;

  }

  .feedback-header {
    display: flex;
    width: 100%;
    max-width: calc(100% - 2em);
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
    margin: 0 1em;

    box-sizing: border-box;

    border-bottom: 1px solid #eee;

  }

  .user-label {
    font-size: 0.7em;
    opacity: 0.6;
  }


  .comment-panel {
    padding: 1em 1em 0.5em 1em;
    box-sizing: border-box;
  }

  .comment-input {
    resize: none;
    width: 100%;
    border-radius: 5px;
    padding: 0.6em 0.8em;
    box-sizing: border-box;
    border-color: #aaa;

  }

  .comment-buttons {
    display: flex;
    justify-content: right;
    width: 100%;
    margin: 0.5em 0;
  }

  .button {
    cursor: pointer;
  }

  .button + .button {
    margin-left: 0.5em;
  }

  .button-small {
    font-size: 12px;
  }

  .button-basic {
    border: none;
    background-color: transparent;
    color: #888;
  }

  .button-basic:hover {
    border: none;
    background-color: #eee;
    color: #555;
  }



  .feedback-items {
    padding: 1em;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .feedback-items .page-title {

  }

  .page-title label {
    text-transform: uppercase;
    font-size: 0.6em;
    margin: 0;

  }

  .page-title p {
    margin: 0;
    color: #555;
    font-size: 0.7em;
  }

  .show-all-toggle {
    text-decoration: underline;
    cursor: pointer;
  }

  .show-all-toggle:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-primary);
  }

  .feedback-footer {
    padding: 0.5em 1em;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    display: flex;
    position: relative;
    background-color: #ebeff2;

  }

  .filters .icon {
    display: flex;
    align-items: center;
  }


  .filters .icon svg {
    width: 1em;
    height: 1em;
  }

  .feedback-footer .filters {
    display: flex;
    align-items: center;
  }

  .feedback-footer .filter-item {
    font-size: 0.8em;
    margin-left: 0.5em;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
  }

  .feedback-footer .filter-item:hover {
    opacity: 1.0;
  }

  .feedback-footer .filter-item.active {
    opacity: 1.0;
    color: white;
    background-color: var(--color-green);
  }

  .icon-button {
    width: 1em;
    height: 1em;
    cursor: pointer;
    opacity: 0.5;
    position: relative;

  }

  .icon-button label {
    position: absolute;
    right: 100%;
    font-size: 0.8em;
    margin-right: 1em;
    display: none;

    color: black;
    width: 100px;
    text-align: right;
    color: #888;
  }


  .icon-button svg {
    width: 0.75em;
    height: 0.75em;
  }


  .icon-button:hover {
    opacity: 1.0;
  }

  .icon-button:hover label {
    display: block;
  }

  .loading {
    font-size: 0.8em;
    color: #aaa;
    margin-top: 1em;
  }

  .total-count-label {
    font-size: 0.6em;
    padding: 0.5em 1em;
    font-weight: bold;
    background: #EEE;
    box-sizing: border-box;
    border-top: 1px solid #ddd;


  }

  .feedback-group {
    margin-bottom: 1em;
  }

  .group-date {
    color: #888;
    font-size: 0.8em;
    margin-left: 0.5em;
  }

  .filter-editor {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    padding: 1em;
    box-sizing: border-box;
    transition: bottom 0.3s;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ccc;
    background-color: #f8f8f8;

  }

  .filter-input {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .filter-input + .filter-input {
    margin-top: 0.5em;
  }

  .warning {
    color: white;
    background: red;
    opacity: 1.0;
  }

  .attention {
    color: black;
    background: yellow;
    opacity: 1.0;
  }
















</style>