<template>
  <div id="app" :data-fullscreen="$store.state.fullscreen" :data-feedback-mode="feedbackMode">
    <div class="app-container" :class="[feedbackMode?'feedback-mode':'',guidedMode?'guided-mode':'',]" >
      <div class="route-container" ref="routeContainer">
        <router-view class="router-content" />
        <demo-menu v-if="$store.state.showMenu" @fullscreen="goFullscreen"></demo-menu>
      </div>



      <feedback-editor class="feedback-editor" v-if="feedbackMode"></feedback-editor>
    </div>
  </div>
</template>

<script>
  import FeedbackEditor from "@/FeedbackEditor";
  import DemoMenu from "@/components/DemoMenu";

  export  default {
    components: {DemoMenu, FeedbackEditor},
    async mounted() {
      console.log("checking accounts");


        window.addEventListener('keydown',this.onKeyDown);
        window.addEventListener('keyup',this.onKeyUp);


      //sso
      if(this.$store.state.sso){
        //const accounts = await this.$msalInstance.getAllAccounts();
        //if(accounts && accounts.length){
          //this.$store.state.account = accounts[0];

          //console.log(accounts[0]);
        if(this.$account){
          this.$store.state.account = this.$account;
          this.$store.commit("login", true);
        }else{
          console.log("No valid user identities available for this app");
        }
      }else{
        this.$store.state.account = { name: "Demo User", username: "demouser" };
      }
    },
    computed: {
      feedbackMode: function(){
        return this.$store.state.feedbackMode;
      },

      guidedMode: function(){
        return this.$store.state.guidedMode;
      }
    },
    methods: {

      goFullscreen: function(){
        this.$refs.routeContainer.firstChild.requestFullscreen();
      },

      onKeyDown: function(e){

        //console.log("keydown", e.key)

        let blockKeyEvents = (this.$route.params.screen?.startsWith("candidate-offer-"))

        if(!this.$store.state.feedbackInput && this.$store.state.loggedIn){

          if(!blockKeyEvents){
            if(e.key == "Shift"){
              this.$store.commit("setShowLinks", true);
            }

            if(e.key == "m"){
              this.$store.commit("showMenu", true);
            }
          }

        }

      },

      onKeyUp: function(e){
        //console.log(e.key);
        if(!this.$store.state.feedbackInput && this.$store.state.loggedIn) {
          if (e.key == "Shift") {
            this.$store.commit("setShowLinks", false);
          }
        }


      },
    }
  }
</script>
<style>

  :root {
     --color-primary: #0068B3;
    --color-blue-lighter: #227CBD;
    --color-green: #00af85;
    --color-green-transparent: rgba(0, 175, 133, 0.5);
  }



  html, body {
    margin: 0;
  }



  html {
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
            45deg,
            rgb(232, 240, 246),
            rgb(232, 240, 246) 10px,
            #F3F7FA 10px,
            #F3F7FA 20px
    );


  }

#app {

  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;


}



#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}



.bg-primary {
  background-color: var(--color-primary)
}

.text-primary {
  color: var(--color-primary);
}

  .text-black {
    color: #222222;
  }

  .text-gray {
    color: #707070;
  }

  .button {
    padding: 0.6em 1.5em;
    border: none;
    border-radius: 0.3em;
    font-size: 1em;
    background-color: var(--color-primary);
    color: white;
  }

  .button-outline {
    padding: 0.6em 1.5em;
    border: 1px solid #AAA;
    color: #888;
    border-radius: 0.3em;
    font-size: 1em;
    background-color: transparent;

  }

  .button-outline:hover {
    border: none;
    border-radius: 0.3em;
    font-size: 1em;
    background-color: var(--color-primary);
    color: white;

  }

  .button[data-inactive] {
    background-color: #cacaca;
    color: white;
    outline: none;
  }

  .link {
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .hidden {
    display: none;
  }

  #app .demo-container {
    box-shadow: 10px 10px 30px rgba(0,0,0,0.3);
  }


  #app[data-fullscreen="true"] .demo-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: inherit;
    width: 100%;
    height: 100%;
    padding-bottom: 0;

  }

  #app[data-fullscreen="true"] .demo-container .max-w-100 {
    max-width: 100%;
  }

  .max-w-100 {
    max-width: 1580px;
  }

  #app .demo-container {
    font-size: 1vw;
    max-width: 1580px;
    width: 100%;
  }

  #app .screen-container {
    font-size: 1vw;

    position: relative;
    width: 100%;
    max-width: 1580px;
  }



  #app[data-feedback-mode="true"] .demo-container {
    width: calc(100vw - 340px);
    font-size: calc((100vw - 340px) / 100);
    padding-bottom: calc((920/1580) * (100% - 340px));
    max-width: 1580px;
  }


  #app[data-feedback-mode="true"] .screen-container {
    width: calc(100vw - 340px);
    font-size: calc((100vw - 340px) / 100);
    padding-bottom: calc((920/1580) * (100% - 340px));
    max-width: 1580px;
    position: relative;
  }

 

  .feedback-editor {
    width: 340px;
    position: fixed;
    right: 0;
    height: 100vh;
    z-index: 1000;
  }


  .app-container {
    width: 100%;
    display: flex;
    height: 100%;
    position: relative;

  }

  .route-container {
    position: relative;
    width: 100%;

  }


  @media(min-width: 1580px){

    #app[data-feedback-mode="true"] .demo-container {
      font-size: calc((100vw - 340px) / 100);
      padding-bottom: calc((920/1580) * (100% - 340px));
      left: auto;
      transform: none;
    }

    #app .demo-container {
      font-size: 15.8px;
      padding-bottom: 920px;
      left: 50%;
      transform: translateX(-50%);
    }



    #app .screen-container {
      font-size: 15.8px;
    }

    #app[data-fullscreen="true"] .demo-container {
      font-size: 1vw;
      padding-bottom: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);

    }

    #app[data-fullscreen="true"] .screen-container {
      font-size: 1vw;
      padding-bottom: 0;
    }
  }

  @media(min-width: 1920px){
    #app[data-feedback-mode="true"] .demo-container {
      font-size: 15.8px;
      padding-bottom: 920px;

      left: calc(50% - 170px);
      transform: translateX(-50%);
    }

  }

</style>
