<template>
  <div class="company-tab" :style="tabStyle">
      <img class="tab-image" :src="tabImage" />
      <div v-if="showLinks">
        <screen-link left="0%" top="0%" :width="linkWidth" height="100%" dot-class="dot-right-center" data-tgt="company-select" @click.native="$screens.toggleOverlay" />
        <company-select style="zIndex:100" v-if="$store.state.overlayPanel === 'company-select'" :select-tgt="selectTgt" :activeCompanies="activeCompanies"></company-select>
      </div>

  </div>
</template>

<script>


import CompanySelect from "@/components/admin/CompanySelect";
export default {
  components: {CompanySelect},
  props: {
    company: {
      type: String,
      default: "enterprise"
    },
    showLinks: {
      type: Boolean,
      default: true
    },

    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: true,
          select: false,
          peo:false,
          initech: false,
        }
      }
    },
    selectTgt: {
      type: String,
      default: ""
    },
    left: {
      type: String,
      default: "14.7%"
    },
    width: {
      type: String,
      default: "20%"
    },
    height: {
      type: String,
      default: "5%"
    },
    top: {
      type: String,
      default: "7.2%"
    }
  },
  computed: {
    tabStyle: function(){
      return {
        top: this.top,
        left: this.left,
        width: this.width,
        height: this.height,
      }
    },
    tabImage: function(){
      switch (this.company){
        case "enterprise":
          return "/img/screens/dashboard/company-select-acme.png"
        case "pro":
          return "/img/screens/dashboard/company-select-polarson.png"
        case "select":
          return "/img/screens/dashboard/company-select-irish.png"
        case "initech":
          return "/img/screens/dashboard/company-select-initech.png"
        case "peo":
          return "/img/screens/dashboard/company-select-peo.png"
        default:
          return "/img/screens/dashboard/company-select-acme.png"
      }
    },
    linkWidth: function(){
      if(this.company === 'initech'){
        return "87%"
      }
      return "72%";

    }
  }
}
</script>

<style scoped>
  .company-tab {
    position: absolute;
    background-color: white;
  }

  .tab-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>