


<template>
<div class="demo-menu">
    <div class="menu-panel">
      <button @click="onCloseClick" class="close-btn">x</button>
      <h1>Paychex Flex Demo  <small>v{{ $store.state.version }}</small></h1>
      <p>Welcome, and thank you for taking the time to see a demo of Paychex FLEX, our all-in-one cloud-based solution for managing your payroll, benefit programs, and much more.</p>

      <p>Display this menu at any time by pressing <span class="button-code">m</span></p>
      <p>To preview active links on a screen, press <span class="button-code">SHIFT</span></p>

      <div class="menu-groups">
        <div class="menu-buttons">
          <div class="">
            <button class="button"  @click="launchFlexDemo">Flex Demo</button>
            <button class="button"  @click="launchEasySetup">Easy Setup Demo</button>
          </div>
        </div>
        <div class="menu-buttons">
          <div class="">
            <button class="button button-small toggle toggle-left" :data-active="guidedMode" @click="onSetGuided(true)">Guided Mode</button>
            <button class="button button-small toggle toggle-right" :data-active="!guidedMode"  @click="onSetGuided(false)">Expert Mode</button>
          </div>
          <div>
            <button class="button button-small"  @click="goFullscreen">Fullscreen</button>
            <a class="button button-small" href="mailto:Paychex_Flex_Web_Demo@paychex.com">Feedback</a>
            <!--          <button  @click="addFeedback" v-if="$store.state.feedbackEnabled">Feedback</button>-->
          </div>

        </div>
      </div>

    </div>

</div>
</template>

<script>
export default {
    name: "demo-menu",
    props: {},
    data: function(){
        return {}
        },
    mounted: function(){
      document.onfullscreenchange = ()=>{
        this.$store.commit("fullscreen", document.fullscreenElement?true:false)
      }
    },
    methods: {
      addFeedback: function(){
        if(document.fullscreenElement){

          document.exitFullscreen();

        }


        this.$store.commit("showMenu", false);
        this.$store.commit("showFeedback", true);
      },

      launchFlexDemo: function(){

        this.$store.dispatch("launchDemo", "flex");

        // if(this.$store.state.demo !== "flex"){
        //   this.$store.state.demo = "flex";
        //   this.$screens.onLinkClick({
        //     target: {
        //       tgt: "dashboard",
        //       tgtMode: "admin",
        //       tgtScrollTop: true
        //     }
        //   })
        // }

        this.$store.commit("showMenu", false);
      },

      onCloseClick: function(){
        this.$store.commit("showMenu", false);
      },

      launchEasySetup: function(){

        this.$store.dispatch("launchDemo", "easysetup");
        // if(this.$store.state.demo !== "easysetup") {
        //   this.$store.state.demo = "easysetup";
        //   this.$screens.onLinkClick({
        //     target: {
        //       tgt: "easy-setup-start",
        //       tgtMode: "easysetup",
        //       tgtScrollTop: true
        //     }
        //   })
        // }

        this.$store.commit("showMenu", false);
      },

      onSetGuided: function(guided){

        //this.guidedMode = guided;
        //this.showMenu = false;

        this.$store.commit("guidedMode", guided);
        //this.$store.commit("showMenu", false);

      },
      goFullscreen: function(){
        if(document.fullscreenElement){

          document.exitFullscreen();

        }else{

          this.$store.state.feedbackMode = false;

          document.getElementById("app").requestFullscreen();

        }
      }
    },
    computed: {
      guidedMode: function(){
        return this.$store.state.guidedMode;
      },
      isFullscreen: function(){
        return document.fullscreenElement;
      }
    }
}
</script>

<style scoped>


.demo-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.demo-menu .menu-panel {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  padding: 5%;
  background-color: var(--color-green);
  color: white;
  border: 10px solid white;
}

.menu-panel h1 {
  margin: 0;
  border-bottom: 2px solid white;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}

.button-code {

  border: 1px solid white;
  padding: 0.25em 0.5em;
  margin: 0 0.25em;
  border-radius: 0.5em;
  font-family: monospace;
}

.menu-buttons {

  display: flex;
  gap: 1em;
  border-top: 1px solid white;
  padding: 1em 0;

}

.menu-buttons .button {
  font-size: 1em;
  border: 1px solid white;
  padding: 1em;
  background: transparent;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-decoration: none;

}



.menu-buttons .button.button-small {
  font-size: 0.9em;
  padding: 0.5em;
  border-radius: 3px;
}

.menu-buttons .button.button-small.toggle {
  border-radius: 0;
}

.menu-buttons .button.button-small.toggle-left {
  border-radius: 0.5em 0 0 0.5em;
}

.menu-buttons .button.button-small.toggle-right {
  border-radius: 0 0.5em 0.5em 0;
}

.menu-buttons .button:hover {
  text-decoration: underline;
}

.menu-buttons .button[data-active="true"] {
  background-color: white;
  color: var(--color-green);
}

.menu-buttons .button + .button {
  margin-left: 1em;
}

.menu-buttons .button.toggle + .button.toggle {
  margin-left: 0;
}

.button.toggle-left {
  border-radius: 0 0 0.5em 0.5em;
}

.menu-panel small {
  font-size: 0.7em;
  font-weight: normal;
}

.menu-groups {
  padding-top: 0em;
  margin-top: 2em;

}

.menu-group-header {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.close-btn {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 2em;
  margin: 0.1em 0.3em;
  position: absolute;
  top:0;
  right: 0;
}

</style>