<template>
    <div :class="'screen-link '+ dotClass" data-active="active" :style="linkStyle" :data-show-dot="showDot" >
      <div class="slot-text" v-if="textSlot"><slot></slot></div>
      <slot v-else></slot>

    </div>
</template>

<script>
    export default {
        name: "screen-link",
        props: {
            active: {
                default: true
            },

            color: {
                default: null
            },

          showDot: {
              default: true
          },

          textSlot: {
              default: false
          },

            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },

            height: {
                default: "3em"
            },

            dotClass: {
              default: ""
            },

          dotPosY: {
            default: "50%"
          }


        },
        data: function () {
            return {}
        },
        methods: {},
        computed: {
            linkStyle: function(){
                return {

                    width: this.width,
                    height: this.height,
                    top: this.top,
                    left: this.left
                }
            }
        }
    }
</script>

<style>


    .screen-link {
        position: absolute;
        box-sizing: border-box;
        cursor: pointer;
        opacity: 1.0;
        transition: 0.3s opacity;
        border: 2px solid transparent;
    }

    .screen-link:hover {
        opacity: 1.0;
    }

    .screen-link-dots:after {
      content: "";
      display: block;
      position: absolute;
      background-color: var(--color-green);
      border-radius: 100%;
      width: 0.8em;
      height: 0.8em;
      box-sizing: border-box;

    }

    .screen-link[data-show-dot="true"]:after {
      content: "";
      display: none;
      position: absolute;
      background-color: var(--color-green);
      box-sizing: border-box;
      border-radius: 100%;
      width: 1em;
      height: 1em;
      border: 2px solid white;
      top: calc(50% - 0.5em);
      left: -0.5em;
    }



    .account-panel-link[data-show-dot="true"]:after {
      content: "";
      display: none;
      position: absolute;
      background-color: var(--color-green);
      box-sizing: border-box;
      border-radius: 100%;
      width: 1rem;
      height: 1rem;
      border: 2px solid white;
      top: calc(50% - 0.25rem);
      left: -0.5rem;
    }

    .screen-link[data-show-dot="true"].dot-top:after {
      top: -0.5em;
    }

    .screen-link[data-show-dot="true"].dot-top-center:after {
      top: -0.5em;
      left: calc(50% - 0.5em);
    }

    .screen-link[data-show-dot="true"].dot-left-center:after {
      top: calc(50% - 0.5em);
      left: -0.375em;
    }

    .screen-link[data-show-dot="true"].dot-right-center:after {
      top: calc(50% - 0.5em);
      left: calc(100% - 0.5em);
    }

    .screen-link[data-show-dot="true"].dot-bottom-center:after {
      top: calc(100% - 0.5em);
      left: calc(50% - 0.5em);
    }

    .screen-link .slot-text {
      display: none;
    }

    .screen-link.text-white .slot-text{
      color: white;
      font-size: 2em;
      text-align: center;
      user-select: none;
      pointer-events: none;
      display: block;
    }

    .screen-link.text-black .slot-text{
      color: black;
      font-size: 2em;
      text-align: center;
      user-select: none;
      pointer-events: none;
      display: block;
    }


</style>