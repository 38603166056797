import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import "./assets/styles/global.css"

import authConfigs from "./msal-config"
import * as msal from "@azure/msal-browser"
import VueGtag from "vue-gtag"
import TimeAgo from "javascript-time-ago";
import ScreenFunctions from './plugins/screenLinks'

/* GLOBAL COMPONENTS */
import ScreenLink from "@/components/ScreenLink";
import InfoBox from "@/components/InfoBox";
import CompanyTab from "@/components/CompanyTab";

Vue.component("screen-link", ScreenLink);
Vue.component("info-box", InfoBox);
Vue.component("company-tab", CompanyTab);

// English.
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)



const devMode = (process.env.VUE_APP_DEMO_MODE === 'development');
const prodMode = (process.env.VUE_APP_DEMO_MODE === 'production');
const gtagId= process.env.VUE_APP_GTAG;

console.log("Dev Mode", devMode)



const msalInstance = new msal.PublicClientApplication({
  auth: authConfigs[process.env.VUE_APP_SSO]
})

const sso_accounts = () => {
  let accounts = msalInstance.getAllAccounts();
  if(accounts && accounts.length){
    return accounts[0]
  }
}

const account = sso_accounts()
let lastLogin = window.localStorage.getItem('lastLogin');
console.log(lastLogin, process.env.VUE_APP_SSO);
if(account || (lastLogin && !process.env.VUE_APP_SSO)){
  Vue.prototype.$account = account;
  if(lastLogin){
    let timeSince = (Date.now() - parseInt(lastLogin))
    if(timeSince < (1000 * 60 * 60 * 8)){
      console.log("logged in recently");
      store.state.loggedIn = true;
    }else{
      console.log("no logged in recently");
    }
  }

  //console.log("sso", account);
}



Vue.prototype.$msalInstance = msalInstance
Vue.prototype.$screens = ScreenFunctions


Vue.config.productionTip = false

Vue.prototype.$timeAgo = new TimeAgo('en-US');



router.beforeEach((to, from, next) => {

 // console.log("before",to.query)

  if(to.query.guided){
    if(to.query.guided === "true"){
      //console.log("setting guided mode", true);
      store.state.guidedMode = true;
    }else{
      //console.log("setting guided mode", false);
      store.state.guidedMode = false;
    }

  }

  if(to.matched.some(record => record.meta.requiresAuth)){

    //console.log("before each", to);

    //to.query.v=store.state.version;

    if(!store.state.loggedIn){
      next({ name: 'Login' })
    }else{
      //console.log("next",next);
      if(from.params.screen && (to.name === 'candidate') && (from.name !== 'candidate')){
        console.log("setting candidate exit screen", from.name, from.params.screen)
        store.state.candidateExitRoute = {
          target: {
            dataset: {
              tgt: from.params.screen,
              tgtMode: from.name
            }
          }
        }
      }

      next()
    }
  }else{
    next()
  }
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to,from) => {
  console.log(to.fullPath);
  if(prodMode){

    router.app.$gtag.pageview(to.fullPath);
  }
})

Vue.use(VueGtag, {
  config: { id: gtagId}
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
