import store from "@/store";
import router from "@/router";

export default {
    showPullout: function(e){
        store.dispatch("showPullout",e)
    },
    closePullout: function(){
        store.dispatch("closePullout");
    },
    showHelp: function(e){
        store.dispatch("showHelp",e)
    },
    closeHelp: function(){
        store.dispatch("closeHelp");
    },
    onLinkClick: function(e){
        //console.log("linkClick",e);
        store.dispatch("linkClick",e)
    },
    toggleOverlay: function(e){
        store.dispatch("toggleOverlay",e)
    },
    setOverlayPanel: function(id){
        store.commit("setOverlayPanel",id);
    },
    onOverlayPanel: function(e){
        store.dispatch("overlayClick",e)
    },
    showExternalPage: function(e){
        store.dispatch("showExternalPage",e)
    },
    showPrevious: function(){
        router.back();
    },
    onSetGuided: function(guided){
        store.dispatch("setGuided", guided);
    },
    onTopNavLink: function(link){
        store.dispatch("topNavLink",link)
    },
}