<template>
  <div class="company-select">
    <img src="/img/screens/dashboard/admin-dashboard-company-select.png?v=10122022" />
    <screen-link v-if="activeCompanies.enterprise" left="3%" top="31%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="" @click.native="setCompany('enterprise')" />
    <screen-link v-if="activeCompanies.pro" left="3%" top="43.8%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="" @click.native="setCompany('pro')" />
    <screen-link v-if="activeCompanies.initech" left="3%" top="69%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="" @click.native="setCompany('initech')" />
    <screen-link v-if="activeCompanies.peo" left="3%" top="81.8%" width="90%" height="12%" dot-class="dot-right-center" data-tgt="" @click.native="setCompany('peo')" />
  </div>
</template>

<script>
export default {
  name: "CompanySelect",
  props: {
    triggerCode: {
      type: String,
      default: ""
    },
    selectionOverride: {},
    selectTgt: {
      type: String,
      default: ""
    },
    activeCompanies: {
      type: Object,
      default: function() {
        return {
          enterprise: true,
          pro: true,
          select: true,
          peo: true,
          initech: true,
        }
      }
    }

  },
  methods: {
    setCompany: function(company){

      if(this.selectionOverride){
        this.selectionOverride(company);
        return;
      }


      this.$store.commit('setCompany', company);

      this.$screens.onLinkClick({
        target:{
          dataset:{
            tgt: this.selectTgt,
            tgtMode: this.$route.name
          }
        }
      })
      this.$screens.toggleOverlay();
    }
  }
}
</script>

<style scoped>

  .company-select {
    background-color: white;
    position: absolute;
    top: calc(100% + 0.5em);
    box-shadow: rgba(0,0,0,0.2) 0 0 10px;

  }

  .company-select img {
    width: calc((320 / 1580) * 100 * 1em);
  }

</style>