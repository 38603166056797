export default {
    cp: {
        clientId: "cd8c4b61-1bf2-4c68-841e-9bb64f153656",
        authority: 'https://login.microsoftonline.com/eff47ea8-1b73-412d-bd70-c31433d4328a'
    },
    px: {
        clientId: "50bb8a62-969d-46a1-85dc-dd9f2e3bf4bf",
        authority: 'https://login.microsoftonline.com/bcc529c5-dfce-4f97-b44f-debd50891d83'
    }
}

//cp is Crystal Pix tenant
//px is Paychex Tenant (production)